import Vue from 'vue'

import Router from 'vue-router'
import jwt_decode from "jwt-decode";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'inicio',
            component: () =>
                import ('@/views/Inicio'),
            meta: {
                guest: true
            }
        },
        {
            path: '/quienessomos',
            name: 'quienessomos',
            component: () =>
                import ('@/views/QuienesSomos'),
            meta: {
                guest: true
            }
        },
        {
            path: '/historia',
            name: 'historia',
            component: () =>
                import ('@/views/Historia'),
            meta: {
                guest: true
            }
        },
        {
            path: '/misionvision',
            name: 'misionvision',
            component: () =>
                import ('@/views/MisionVision')
        },
        {
            path: '/valores',
            name: 'valores',
            component: () =>
                import ('@/views/Valores'),
            meta: {
                guest: true
            }
        },
        // {
        //     path: '/whois',
        //     name: 'whois',
        //     component: () =>
        //         import ('@/views/WhoIs'),
        //     meta: {
        //         guest: true
        //     }
        // },
        {
            path: '/buscardominio',
            name: 'buscardominio',
            component: () =>
                import ('@/views/BuscarDominio'),
            meta: {
                guest: true
            }
        },
        {
            path: '/informaciongeneral',
            name: 'informaciongeneral',
            component: () =>
                import ('@/views/InformacionGeneral'),
            meta: {
                guest: true
            }
        },
        {
            path: '/PasosDom',
            name: 'pasosdominio',
            component: () =>
                import ('@/views/pasosObtenerDom'),
            meta: {
                guest: true
            }
        },
        {
            path: '/requisitos',
            name: 'requisitos',
            component: () =>
                import ('@/views/requisitosDominio'),
            meta: {
                guest: true
            }
        },
        {
            path: '/requisitos',
            name: 'requisitos',
            component: () =>
                import ('@/views/Requisitos'),
            meta: {
                guest: true
            }
        },
        // {
        //     path: '/iniciarsesion',
        //     name: 'admin-iniciarsesion',
        //     component: () =>
        //         import ('@/views/IniciarSesion'),
        //     meta: {
        //         guest: true
        //     }
        // },
        {
            path: '/recuperarcontraseña',
            name: 'admin-recuperarcontraseña',
            component: () =>
                import ('@/views/RecuperarContraseña'),
            meta: {
                guest: true
            }
        },
        // {
        //     path: '/crearusuario',
        //     name: 'admin-crearusuario',
        //     component: () =>
        //         import ('@/views/CrearUsuario'),
        //     meta: {
        //         guest: true
        //     }
        // },
        {
            path: '/contactenos',
            name: 'contactenos',
            component: () =>
                import ('@/views/Contactenos'),
            meta: {
                guest: true
            }
        },
        {
            path: '/faqs',
            name: 'faqs',
            component: () =>
                import ('@/views/Faqs'),
            meta: {
                guest: true
            }
        },
        {
            path: '/cursocisco',
            name: 'cursocisco',
            component: () =>
                import ('@/views/CursoCisco'),
            meta: {
                guest: true
            }
        },
        {
            path: '/compradominio',
            name: 'compradominio',
            component: () =>
                import ('@/views/CompraDominio'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/panel',
            name: '',
            component: () =>
                import ( /* webpackChunkName: "Dashboard"*/ '@/views/dashboard/Dashboard'),
            children: [{
                    path: '',
                    name: 'admin-index',
                    component: () =>
                        import ('@/views/dashboard/Index'),
                }, {
                    path: 'dominios',
                    name: 'admin-dominios',
                    component: () =>
                        import ( /* webpackChunkName: "Dominios"*/ '@/views/dashboard/pages/Dominios'),
                },
                {
                    path: 'cuenta',
                    name: 'admin-cuenta',
                    component: () =>
                        import ( /* webpackChunkName: "Cuenta"*/ '@/views/dashboard/pages/Cuenta'),
                },
                {
                    path: 'terminos',
                    name: 'admin-terminoscondiciones',
                    component: () =>
                        import ( /* webpackChunkName: "TerminiosCodiciones"*/ '@/views/dashboard/pages/TerminosCondiciones')
                },
                {
                    path: 'dominios/detalle/:id',
                    name: 'admin-detalledominio',
                    component: () =>
                        import ( /* webpackChunkName: "DetalleDominio"*/ '@/views/dashboard/pages/DetalleDominio')
                },
                {
                    path: 'dominios/editar/:id',
                    name: 'admin-editardominio',
                    component: () =>
                        import ( /* webpackChunkName: "EditarDominio"*/ '@/views/dashboard/pages/EditarDominio')
                }
            ],
            meta: {
                requiresAuth: true
            }
        }
    ]

})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem(window.atob('usr')) == null) {
            next({
                path: '/IniciarSesion',
                params: { nextUrl: to.fullPath }
            })
        } else {
            let decodeToken = jwt_decode(localStorage.getItem(atob("usr")));
            let exp = decodeToken.exp;
            const current = Math.floor(Date.now() / 1000);
            if (current > exp) {
                localStorage.removeItem(window.atob('usr'));
                next({
                    path: '/IniciarSesion',
                    params: { nextUrl: to.fullPath }
                })
            } else
                next()
        }
    } else {
        next()
    }
})
export default router