import Vue from 'vue'
import './plugins/meta'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import apiecommerce from "./shared/keys"
import VueToast from 'vue-toast-notification';
import './assets/spinner.css';

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.use(apiecommerce);
Vue.use(VueToast);
new Vue({
    vuetify,
    store,
    router,
    axios,
    apiecommerce,
    render: h => h(App)
}).$mount('#app')