import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        cons: {
            iconfont: 'fa',
        },
        themes: {
            light: {
                primary: '#FFF',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                cardActiveorange: '#F57C00',
                cardAdtivegreen: '#43A047',
                cardActivedanger: '#D32F2F',
                uniColor: '#003791',
                cardPendiente: '#424242',
                cardPendienteActivacion: '#A5D6A7',
                label: '#616161',
                cardPanel: '#F5F5F5',
                titleCard: '#86CB1C',

            }
        }
    }
})